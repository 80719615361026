<template>
  <va-card title="Zee Report">
    <form>
      <span class="va-form-label va-form-required-label">Start Date</span>
      <datepicker
        class="date_picker"
        v-model="start_date"
        format="MMM dd yyyy"
        :error="!!startdateErrors.length"
        :error-messages="startdateErrors"
      /><br>
      <span class="va-form-label va-form-required-label">End Date</span>
      <datepicker
        class="date_picker"
        v-model="end_date"
        format="MMM dd yyyy"
        :error="!!enddateErrors.length"
        :error-messages="enddateErrors"
      /><br>
      <span class="va-form-label va-form-required-label">Format</span>
      <va-select
        placeholder="Select Format"
        v-model="format"
        textBy="id"
        :options="formatArr"
        :error="!!formatErrors.length"
        :error-messages="formatErrors"
      />
      <div class="d-flex justify--end mt-3">
        <va-button type="submit" class="my-0" @click.prevent="getReport()">Get Report</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
import Datepicker from 'vuejs-datepicker'
Vue.use(vueResource)
export default {
  name: 'zee_report',
  components: { Datepicker },
  data () {
    return {
      start_date: new Date(),
      end_date: new Date(),
      format: '',
      startdateErrors: [],
      enddateErrors: [],
      formatErrors: [],
      formatArr: [{ id: 'Excel' }, { id: 'PDF' }],
    }
  },
  computed: {
    reportReady () {
      return !this.startdateErrors.length && !this.enddateErrors.length && !this.formatErrors.length
    },
  },
  methods: {
    getReport () {
      this.startdateErrors = this.start_date ? [] : ['Start Date is required']
      this.enddateErrors = this.end_date ? [] : ['End Date is required']
      this.formatErrors = this.format ? [] : ['Format is required']
      if (!this.reportReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      var payload = {
        start_date: this.start_date,
        end_date: this.end_date,
        format: this.format.id,
      }
      var extensionMap = {
        PDF: '.pdf',
        Excel: '.csv',
      }
      var map = {
        PDF: 'data:text/pdf;base64,',
        Excel: 'data:text/csv;charset=utf-8,',
      }

      var exportType = this.format.id
      this.$http.post(config.menu.host + '/zee_report', payload).then(response => {
        var link = document.createElement('a')
        var filename = response.body.file_name + extensionMap[exportType]
        var url = map[exportType] + escape(response.body.payload)
        link.href = url
        link.download = filename
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  },
}
</script>
